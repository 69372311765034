.User {
    color: #545454;
    text-align: left;
    max-width: 350px;
    margin: 0 auto;
}

.User .profileImage {
   width: 100%; 
   max-width: 100%; 
   object-fit: cover;
}

.User .profileName {
   color: #828282;
   padding: 20px 20px 0px 20px;
}

.User .profileImageContainer {
    width: 100%;
}

.User .profileText {
    border-bottom: 1px #F1F1F1 solid;
    color: #828282;
    font-size: 90%;
    padding: 10px 20px;
}
