.Header h1 {
    background: #808080;
    padding: 20px;
    margin: auto;
    max-width: 450px;
    font-family: 'Lato', Arial, sans-serif;
    font-size: 140%;
    font-weight: 300;
    text-align: center;
    color: #fff;
    box-sizing: border-box;
}

.App {
    text-align: center;
    max-width: 410px;
    margin: auto;
    padding: 20px;
    background: #f4f7f8;
    font-family: 'Lato', Arial, sans-serif;
}


.App link {
  color: #61dafb;
}


.App h1 {
    background: #808080;
    padding: 20px 0;
    font-size: 140%;
    font-weight: 600;
    text-align: center;
    color: #fff;
    box-sizing: border-box;
}


.App .helperText {
    color: #929292;
    font-size: 80%;
    padding: 5px;
}

.App .counterText {
    background: #D4D7D8;
    padding: 10px 0;
    font-size: 110%;
    text-align: center;
    color: #444444;
    width:80%;
    text-align: center;
    display: block;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.App input[type="text"],
.App input[type="date"],
.App input[type="datetime"],
.App input[type="email"],
.App input[type="number"],
.App input[type="search"],
.App input[type="time"],
.App input[type="url"],
.App textarea,
.App input[type="textarea"],
.App select {
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    outline: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    background: #fff;
    border: 1px solid #ccc;
    padding: 3%;
    margin-bottom:4%;
    color: #555;
    font-size: 95%;
}

.App input[type="textarea"],
.App textarea {
    min-height: 170px;
}

    .App input[type="submit"],
    .App button,
    .App input[type="button"] {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        width: 80%;
        padding: 3%;
        margin-bottom: 4%;
        background: #FD3577;
        border-bottom: 2px solid #FF7058;
        border-top-style: none;
        border-right-style: none;
        border-left-style: none;
        color: #fff;
    }

.App input[type="submit"]:hover,
.App input[type="button"]:hover {
    background: #FE5765;
}

.App input[type="text"]:focus,
.App input[type="date"]:focus,
.App input[type="datetime"]:focus,
.App input[type="email"]:focus,
.App input[type="number"]:focus,
.App input[type="search"]:focus,
.App input[type="time"]:focus,
.App input[type="url"]:focus,
.App textarea:focus,
.App select:focus {
    box-shadow: 0 0 5px #43D1AF;
    padding: 3%;
    border: 1px solid #43D1AF;
}

.inputPrompt {
    margin-bottom: 10px;
}


.swipeButtons {
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.like {
    width: 64px;
    padding: 17px 0 17px;
    min-width: 64px;
    border-radius: 100%;
    box-shadow: 0 0 6px #B0B0B0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.spacer {
    width: 100px;
}

.dislike {
    width: 64px;
    height: 64px;
    min-width: 64px;
    border-radius: 100%;
    box-shadow: 0 0 6px #B0B0B0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white;
}

.dislike img {
    width: 26px;
    height: 26px;
}

.like img {
    width: 30px;
    height: 30px;
}

.my-node-enter {
    opacity: 0;
}

.my-node-enter-active {
    opacity: 1;
    transition: opacity 400ms;
}

.my-node-exit {
    opacity: 1;
}

.my-node-exit-active {
    opacity: 0;
    transition: opacity 400ms;
}